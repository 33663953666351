import React, { useState, ComponentType } from 'react';

import dynamic from 'next/dynamic';
import styled from 'styled-components';

import {
  NewButton,
  NewButtonTypes,
  NewButtonSizes,
} from '../../components/Toolkit/NewButton/NewButton';
import {
  FilterRequestAPI,
  SearchRequest,
} from '../../services/Filters/FilterTypes';
import { extractActiveFilters } from '../../services/Search/SearchUtils';
import { FilterOptions, SECTIONS } from '../../types';

import { showAreaCounts } from './searchPageUtils';
import FilterManager from '../../services/Filters/FilterManager';
import Sort from '../Filters/Sort/Sort';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
} from '@dist-property-frontend/toolkit';
import { Badge } from '../Toolkit/Badge/Badge';
const FiltersSearchModal: ComponentType<any> = dynamic(
  () => import('../../components/Filters/UI/FiltersSearchModal'),
  { ssr: false },
);

type SearchPageFiltersProps = {
  filterAPI: FilterRequestAPI[];
  headerTheme?: 'RESIDENTIAL' | 'COMMERCIAL';
  hiddenFilterAPI?: FilterRequestAPI[];
  hideSortOption?: boolean;
  isActiveSavedSearchButton?: boolean;
  isLoadingSection?: boolean;
  isMapView?: boolean;
  isSoldSection?: boolean;
  isRecentSection?: boolean;
  locationSearchPlaceholderText?: string;
  onChange: (query: any) => void;
  onFiltersChange: (query: any) => void;
  onSaveSearchButtonClick?: (method: string) => any;
  pageVariant?: string;
  query: any;
  queryParamsToPersist?: any;
  resetAllFilters: () => void;
  resetCallback: () => void;
  searchQuery: SearchRequest;
  section: SECTIONS;
  showHeaders?: boolean;
  totalResults: number;
};

type StyledFilterProps = {
  pageVariant?: string;
};

const StyledSaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e7e4e4;
  border-radius: 0;
  height: 52px;
  width: 14%;
  max-width: 56px;
  @media only screen and (min-width: 704px) {
    height: 65px;
    border-radius: 4px;
    border-right: 1px solid #e7e4e4;
    margin-left: 1%;
    width: 16%;
    max-width: 90px;
  }
`;

const FiltersWrapper = styled.div<{ isMapView?: boolean }>`
  display: ${({ isMapView }) => (isMapView ? 'none' : 'flex')};
  flex-wrap: wrap;
  position: sticky;
  top: -100px;
  z-index: 500;

  @media only print, screen and (min-width: 704px) {
    margin: 0 auto;
    max-width: 1040px;
    padding: 36px 0px;
    position: static;
    top: initial;
  }
  @media only print, screen and (min-width: 900px) {
    display: flex;
  }
`;

const Filters = styled.div<StyledFilterProps>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const BlockyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  height: 50px;

  @media only print, screen and (min-width: 704px) {
    height: 65px;
  }

  & .hasOpenMenu {
    border-bottom-left-radius: 0 !important;
    border-bottom: 0 !important;
    button {
      outline: 0;
    }
  }

  > button,
  > div > button {
    @media only screen and (min-width: 704px) {
      max-width: 151px;
    }
  }

  > div,
  > button {
    background-color: #ffffff;
    border: 1px solid #e7e4e4;
    flex: 1 1 auto;
    flex-basis: 28%;

    @media only print, screen and (min-width: 704px) {
      flex-basis: 25%;
      height: 65px;
    }
  }

  > div:nth-child(1),
  > button:nth-child(1) {
    background-color: #ffffff;
    border: 1px solid #e7e4e4;
    border-left: none;
    border-right: none;
    flex: 1 1 auto;
    flex-basis: 33%;
    font-size: 12px;

    @media only print, screen and (min-width: 704px) {
      height: 65px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid #e7e4e4;
      flex-basis: 30%;
    }
  }

  > div:nth-child(3),
  > button:nth-child(3) {
    background-color: #ffffff;
    border: 1px solid #e7e4e4;
    border-left: none;
    border-right: none;
    border-radius: 0;
    flex: 1 1 auto;
    flex-basis: 17%;

    @media only print, screen and (min-width: 704px) {
      height: 65px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #e7e4e4;
      flex-basis: 25%;
    }

    &:hover {
      border: 1px solid #e7e4e4;
      border-left: none;
      background-color: #ffffff;
    }
  }
`;

//On the recent section, the location filter should be full width and distance radius should be hidden
type LocationWrapperProps = {
  isRecentSection: boolean;
  isSoldSection: boolean;
};

const LocationWrapper = styled.div<LocationWrapperProps>`
  position: relative;
  flex: 1 1 100%;
  max-width: 100%;
  padding: 20px 16px 24px;
  background: #ffffff;

  @media only print, screen and (min-width: 704px) {
    padding: 0;
    margin-right: 8px;
    flex: ${(props) => (props.isRecentSection ? '1 1 100%' : '1 1 454px')};
    max-width: ${(props) => (props.isRecentSection ? '100%' : '454px')};
  }

  > div {
    width: 100%;

    @media only print, screen and (min-width: 704px) {
      height: 65px;
    }

    /* Hide radius on page */
    .small-radius-filter {
      display: none;

      @media only screen and (min-width: 704px) {
        display: ${(props) =>
          props.isRecentSection || props.isSoldSection ? 'none' : 'initial'};
      }
    }
  }
`;

const MobileOnlyOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;

  @media only print, screen and (min-width: 704px) {
    display: none;
  }
`;

const MobileMapFilterButtonWrapper = styled.div`
  position: fixed;
  right: 16px;
  top: 122px;
  z-index: 700;
  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

const SaveSearchMobileMapButtonWrapper = styled.div`
  position: fixed;
  right: 16px;
  top: 170px;
  z-index: 700;
  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

const FilterModalButtonWrapper = styled.div`
  height: 52px;
  width: 100%;

  @media only screen and (min-width: 704px) {
    height: 65px;
    max-width: 151px;
  }

  .button-container,
  button {
    height: 100%;
    font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

    @media only screen and (max-width: 700px) {
      ${({ theme }) => theme.fontSize.B12}
    }
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`;

export const SearchPageFilters = (props: SearchPageFiltersProps) => {
  const {
    filterAPI,
    headerTheme = 'RESIDENTIAL',
    hiddenFilterAPI,
    hideSortOption = false,
    isActiveSavedSearchButton,
    isLoadingSection = false,
    isMapView,
    isSoldSection,
    isRecentSection,
    locationSearchPlaceholderText,
    onChange,
    onFiltersChange,
    pageVariant,
    query,
    queryParamsToPersist,
    resetAllFilters,
    searchQuery,
    section,
    showHeaders = false,
    totalResults,
  } = props;

  let ifFromDivClick = false;
  const [isOpen, setIsOpen] = useState(false);

  const onMobileOpenModalWhenHiddenDivIsClicked = () => {
    ifFromDivClick = true;
    setIsOpen(true);
  };

  const openModal = () => {
    setIsOpen(true);
    onModalOpen();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onModalOpen = () => {
    ifFromDivClick = false;
  };

  const handleSaveSearchButtonClick = () => {
    const { onSaveSearchButtonClick, isActiveSavedSearchButton } = props;
    const method = isActiveSavedSearchButton ? 'remove' : 'create';

    onSaveSearchButtonClick && onSaveSearchButtonClick(method);
  };

  const activeFiltersCount = extractActiveFilters(
    filterAPI.concat(hiddenFilterAPI as any),
  ).length;

  const locationFilter = filterAPI
    .filter((filter) => filter.name === 'location')
    .shift();
  const quickAccessFilters = filterAPI
    .filter((filter) => filter.name !== 'location')
    .slice(0, 2);
  const shouldShowAreaCounts = showAreaCounts(searchQuery);
  const isAgentTool =
    pageVariant &&
    (pageVariant === 'VALUATION_TOOL' ||
      pageVariant === 'AGENT_OFFERS' ||
      pageVariant === 'VALUATION_TOOL_V2');

  const currentPath =
    section === 'student-accommodation-for-rent' ||
    section === 'student-accommodation-to-share'
      ? '/colleges'
      : '';

  const isSaveSearchButtonHidden =
    isRecentSection || isAgentTool || isSoldSection;

  // Currently we only disable the save search button if there is a custom shape polygon drawn
  // So we disable the button if the urlParam geoSearchType=CUSTOM_SHAPES or it has a polygon param
  // Normally you would not have polygon param in the url when not a custom shape but it is possible
  // and since apps don't support it we should cover the case for now
  const isSaveSearchButtonDisabled =
    query?.geoSearchType === 'CUSTOM_SHAPES' || query.polygon;

  return (
    <>
      {!isOpen && isMapView && (
        <>
          <MobileMapFilterButtonWrapper>
            <Button
              buttonSize={ButtonSizes.SMALL}
              buttonType={ButtonTypes.TERTIARY}
              data-testid="open-filter-modal-from-map"
              Icon={<Icon icon="FILTERS" size={16}></Icon>}
              onClick={openModal}
            />
            {activeFiltersCount > 0 && (
              <BadgeContainer>
                <Badge
                  ariaLabel={`badge-text-${activeFiltersCount}`}
                  backgroundColor="#4170c4"
                  textColor="#ffffff"
                  dataTestId={`badge-${activeFiltersCount}`}
                >
                  {activeFiltersCount}
                </Badge>
              </BadgeContainer>
            )}
          </MobileMapFilterButtonWrapper>
          <SaveSearchMobileMapButtonWrapper>
            <Button
              buttonSize={ButtonSizes.SMALL}
              buttonType={ButtonTypes.TERTIARY}
              data-testid="save-filters-from-map"
              disabled={isSaveSearchButtonDisabled}
              Icon={
                <Icon
                  icon={isActiveSavedSearchButton ? 'HEARTFILLED' : 'HEART'}
                  size={16}
                ></Icon>
              }
              onClick={handleSaveSearchButtonClick}
            />
          </SaveSearchMobileMapButtonWrapper>
        </>
      )}

      <FiltersWrapper data-testid="searchFiltersDesktop" isMapView={isMapView}>
        <Filters pageVariant={pageVariant} data-testid="quick-links">
          {locationFilter && (
            <LocationWrapper
              isRecentSection={isRecentSection}
              isSoldSection={isSoldSection}
            >
              <FilterManager
                filterAPI={[locationFilter]}
                onFiltersChange={onFiltersChange}
                alternativeStyle={true}
                showLabel={false}
                options={{
                  LocationInputBox: {
                    selectedSection: section,
                    shouldShowAreaCounts: shouldShowAreaCounts,
                    placeholderText: locationSearchPlaceholderText,
                    currentPath,
                  },
                }}
              />
              <MobileOnlyOverlay
                tabIndex={0}
                role="button"
                onClick={onMobileOpenModalWhenHiddenDivIsClicked}
                onKeyPress={onMobileOpenModalWhenHiddenDivIsClicked}
              />
            </LocationWrapper>
          )}
          <BlockyWrapper>
            <FilterManager
              filterAPI={quickAccessFilters}
              onFiltersChange={onFiltersChange}
              alternativeStyle
              showLabel={false}
              variantOverride={'DAFT_BLOCKY'}
            />
            {!isRecentSection && (
              <FilterModalButtonWrapper>
                <NewButton
                  buttonText={`${
                    activeFiltersCount && activeFiltersCount > 0
                      ? `${activeFiltersCount + ' '}Filter${
                          activeFiltersCount > 1 ? 's' : ''
                        }`
                      : 'Filters'
                  }`}
                  buttonType={NewButtonTypes.GHOST}
                  buttonSize={NewButtonSizes.SMALL}
                  onClick={openModal}
                  fullWidth
                  data-testid={'open-filters-modal'}
                />
              </FilterModalButtonWrapper>
            )}
            {isOpen && (
              <FiltersSearchModal
                totalResults={totalResults}
                resetCallback={resetAllFilters}
                searchQuery={searchQuery}
                onModalOpen={onModalOpen}
                showHeaders={isRecentSection ? false : showHeaders}
                isLoadingSection={isLoadingSection}
                filters={
                  ((options: FilterOptions = {}) => (
                    <FilterManager
                      filterAPI={filterAPI}
                      onFiltersChange={onFiltersChange}
                      alternativeStyle={false}
                      appendToId="Modal"
                      showLabel
                      options={{
                        ...options,
                        LocationInputBox: {
                          initWithFocus: ifFromDivClick,
                          shouldShowOnDesktop: false,
                          selectedSection: section,
                          shouldShowAreaCounts: shouldShowAreaCounts,
                          currentPath,
                          placeholderText: locationSearchPlaceholderText,
                          ...options.LocationInputBox,
                        },
                        TextInput: {
                          shouldShowButton: false,
                        },
                      }}
                    />
                  )) as any
                }
                hiddenFilters={
                  (() => (
                    <FilterManager
                      filterAPI={hiddenFilterAPI}
                      onFiltersChange={onFiltersChange}
                      alternativeStyle={false}
                      appendToId="Modal"
                      showLabel
                      options={{
                        TextInput: {
                          shouldShowButton: false,
                        },
                      }}
                    />
                  )) as any
                }
                hiddenFilterAPI={hiddenFilterAPI}
                renderSortFilter={() => {
                  if (hideSortOption || isRecentSection) return null;
                  return (
                    <Sort
                      id="mobile-sort-filter"
                      onChange={onChange}
                      currentValue={query['sort']}
                      showLabel={true}
                      hasActiveBorder={true}
                      section={section}
                    />
                  );
                }}
                section={section}
                headerTheme={headerTheme}
                queryParamsToPersist={queryParamsToPersist}
                openModal={openModal}
                closeModal={closeModal}
                isOpen={isOpen}
              />
            )}
            {!isSaveSearchButtonHidden && (
              <StyledSaveButtonWrapper>
                <NewButton
                  buttonSize={NewButtonSizes.SMALL}
                  buttonText="Save"
                  buttonTextActive="Saved"
                  buttonType={NewButtonTypes.GHOST}
                  data-testid="save-search-button"
                  disabled={isSaveSearchButtonDisabled}
                  displayTextOnDesktopOnly={true}
                  fullWidth={true}
                  iconName="HEART"
                  isActive={isActiveSavedSearchButton}
                  onClick={handleSaveSearchButtonClick}
                />
              </StyledSaveButtonWrapper>
            )}
          </BlockyWrapper>
        </Filters>
      </FiltersWrapper>
    </>
  );
};
